@import "../../scss/mixins/media-query";
@import "../../scss/setup/variable";

.admin-container {
    height: 100%;

    .admin-list-control-section {
        .btn {
            margin-right: 10px;
        }
    }

    .admin-list-table {
        display: flex;
        flex-direction: row;
        height: calc(100% - 100px);

        @include md() {
            display: block;
            flex-direction: column;
            padding: 0;
        }

        .list-view-wrap {
            overflow: auto;
            width: 100%;
            transition: all .3s ease-out;

            @include md() {
                width: 100%;
                height: 100%;
                max-height: calc(100vh - #{$main-mobile-header-tot-height});
                overflow: scroll;
            }
        }

        .cell-checkbox {
            padding: 0;

            label {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                height: 100%;
                width: 38px;
            }

            input[type="checkbox"] {
                cursor: pointer;
            }
        }

        .account-field {
            color: #007bff;
            text-decoration: none;
            background-color: transparent;
            cursor: pointer;

            &:hover {
                color: #0056b3;
                text-decoration: underline;
            }
        }
    }
}
